<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$t($route.name)"
    />

    <v-spacer />

    <div class="mx-3" />
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-flag</v-icon>
        </v-btn>
      </template>
      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item @click.native="clickLang('en')">
            EN
          </app-bar-item>
          <app-bar-item @click.native="clickLang('de')">
            DE
          </app-bar-item>
          <app-bar-item @click.native="clickLang('it')">
            IT
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
            :to="p.to"
          >
            <v-list-item-title v-text="$t(p.title)" />
          </app-bar-item>
        </template>
        <template>
          <app-bar-item
            :key="`app-bar-logout`"
            @click.native="logout()"
          >
            {{ $t('logout') }}
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { VHover, VListItem } from 'vuetify/lib'
  import authService from '../../../authService'
  import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
  import i18n, { changeLang } from '@/i18n'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(
                  VListItem,
                  {
                    class: {
                      'black--text': !hover,
                      'white--text secondary elevation-12': hover,
                    },
                    props: {
                      activeClass: '',
                      dark: hover,
                      link: true,
                      ...this.$attrs,
                    },
                  },
                  this.$slots.default,
                )
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: ['Keine neuen Nachrichten'],
      profile: [
        { title: 'profile', to: '/app/user' },
        // { title: 'settings', to: '/app/company' },
        { divider: true },
      ],
    }),

    computed: {
      ...mapGetters({
        account: 'account',
      }),
      ...mapState(['drawer']),
      ...mapState('userStore', {
        updateUserError: 'error',
      }),
    },
    watch: {},
    mounted () {
      if (this.account && this.account.language) {
        this.changeLanguage(this.account.language)
      }
    },

    methods: {
      ...mapActions('userStore', {
        updateUser: 'updateItem',
      }),
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      logout () {
        authService.logout().then(() => {
          console.log('logged out')
        })
      },
      async updateUserProfileWithLanguage (lang) {
        const user = this.account
        user.language = lang
        await this.updateUser({ id: user._id, item: user })

        if (this.updateUserError) {
          console.error('Updating user-profile with language failed')
        }
      },
      changeLanguage (lang) {
        i18n.locale = lang
        changeLang(lang)
      },
      clickLang (lang) {
        this.changeLanguage(lang)
        this.updateUserProfileWithLanguage(lang)
      },
    },
  }
</script>
